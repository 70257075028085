<template>
  <body id="accueil"  data-bs-offset="200" class="bg-white position-relative" tabindex="-1" style="outline: none;">
    <!--begin::Main-->
    <div class="d-flex flex-column flex-root">
        <router-view></router-view>
    </div>
</body>
</template>

<script>
export default {

}
</script>

<style>

</style>